import React from 'react';

const App = () => {
  return (
    <div>
      <h1>Welcome to Wangsu VIP</h1>
      <p>This is a simple React application.</p>
    </div>
  );
};

export default App;
