import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles.css'; // 如果 styles.css 存在

import App from './components/App'; // 确保路径正确

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') // 对应 public/index.html 中的 root div
);
